import React from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import Image from "../../assets/images/resend-link-picture.svg"
import { Container } from "../../components/compartilhados/Container"
import { Main, InfoWrapper, IntroWrapper, ImageWrapper } from "../../styles/saude/reenviar-link-verificacao"

export default function ReenviarLinkVerificacao() {
    return (
        <Container>
            <Header />
            <Main>
                <InfoWrapper>
                    <IntroWrapper>
                        <h1>Vamos tentar de novo?</h1>
                        <p>O link de verificação que mandamos em seu e-mail expirou, mas vamos enviar um novo para você!<br /><br />Clique em <b>Reenviar link</b> e verifique seu e-mail.</p>
                    </IntroWrapper>
                    <Button
                        title="Reenviar link"
                        noLink
                    />
                </InfoWrapper>
                <ImageWrapper>
                    <Image className="main-image" />
                </ImageWrapper>
            </Main>
            <Footer />
        </Container>
    )
}